import React from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseDialogIcon from "../../../public/images/close.svg";

import "./DialogComponent.scss";

const DialogComponent = ({
    open,
    onClose,
    children,
    classes = "",
    paper_classes = "",
    root_classes = "",
    close_image = CloseDialogIcon
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                root: `default_dialog_root ${root_classes}`,
                paper: `paper_custom_dialog ${paper_classes}`
            }}
        >
            <div className={`dialog_wrapper ${classes}`}>
                <button onClick={onClose} className="dialog_close_button">
                    <img src={close_image} alt="PRORAB close icon" title="PRORAB Закрыть" />
                </button>
                {children}
            </div>
        </Dialog>
    );
};

export const SpecialDialog = ({
    open,
    onClose,
    children,
    title,
    classes = "",
    paper_classes = "",
    root_classes = "",
    close_image = CloseDialogIcon
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                root: `default_dialog_root ${root_classes}`,
                paper: `paper_custom_dialog ${paper_classes}`
            }}
        >
            <div className={`dialog_wrapper ${classes}`}>
                <header className="ow-dialog__header">
                    <div className="ow-dialog__title">{title}</div>
                    <button onClick={onClose} className="dialog_close_button">
                        <img src={close_image} alt="PRORAB close icon" title="PRORAB Закрыть" />
                    </button>
                </header>
                {children}
            </div>
        </Dialog>
    );
};

export default DialogComponent;
